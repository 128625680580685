import axios, { AxiosInstance, AxiosPromise, Method, CancelToken } from 'axios';

import type { TService, TToken } from '../../auth/types';
import { getAccessToken } from '../Cookies.service';

import { Headers, Methods } from './constants';
import { paramsSerializer, setHeaders } from './helpers';

axios.defaults.withCredentials = false;
axios.defaults.timeout = 150000;

export type TOptions = {
  contentType?: string;
  data?: any;
  method?: Method;
  endPoint?: string;
  url?: string;
  headers?: any;
  params?: any;
  cancelToken?: CancelToken;
  accessToken?: string;
};

export const apiService = (baseURL: string, service: AxiosInstance = axios) => {
  const getHeaders = (contentType: string, token?: string) => {
    const accessToken = token || getAccessToken()?.access_token;
    const headers: any = {
      [Headers.CONTENT_TYPE]: contentType,
    };

    if (accessToken) {
      headers[Headers.AUTHORIZATION] = `Bearer ${accessToken}`;
    }

    return headers;
  };

  const request: (options: TOptions) => AxiosPromise = ({
    contentType = 'application/json',
    endPoint = '',
    headers,
    method,
    accessToken,
    url = baseURL,
    ...options
  }) =>
    service({
      url: `${url}${endPoint}`,
      method,
      headers: {
        ...getHeaders(contentType, accessToken),
        ...headers,
      },
      ...options,
      paramsSerializer,
    });

  const get: (options: TOptions) => any = ({ data, ...options }) =>
    request({
      ...options,
      method: Methods.GET,
    });

  const head: (options: TOptions) => any = ({ data, ...options }) =>
    request({
      ...options,
      method: Methods.HEAD,
    });

  const post = (options: TOptions) =>
    request({ method: Methods.POST, ...options });
  const put = (options: TOptions) =>
    request({ method: Methods.PUT, ...options });
  const patch = (options: TOptions) =>
    request({ method: Methods.PATCH, ...options });

  return {
    delete: (options: TOptions) =>
      request({ method: Methods.DELETE, ...options }),
    get,
    post,
    put,
    patch,
    head,
  };
};

export const createInstance = (
  module: TService,
  authToken: string | boolean | undefined = getAccessToken()?.access_token,
) => {
  const headers: {
    [Headers.AUTHORIZATION]?: string;
  } = {};

  if (authToken) {
    headers[Headers.AUTHORIZATION] = `Bearer ${authToken}`;
  }

  return axios.create({ headers });
};

export const updateInstanceToken = (
  instance: AxiosInstance,
  tokens?: TToken,
) => {
  const accessToken = tokens?.access_token;

  setHeaders(instance, accessToken);
};
