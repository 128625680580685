import { differenceInMinutes } from 'date-fns';
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';

import { setInitialState as setUserInitialState } from '../../entities/user/reducer';
import { UserInfoSelector } from '../../entities/user/selectors';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setMenuInitialState } from '../menu/reducer';
import { Routes } from '../router/types';
import { useAuthDBService } from '../services/AuthDB.service';
import {
  clearAccessToken,
  clearUsername,
  getAccessToken,
  saveAccessToken,
} from '../services/Cookies.service';
import { useNodeBackService } from '../services/NodeBack.service';

import {
  CHECK_SESSION_INTERVAL,
  CHECK_TOKEN_INTERVAL,
  LOCAL_STORAGE_KEY_LAST_PAGE,
  MINIMAL_TOKEN_DIFF,
  StatusCodes,
} from './const';
import { useConnectToken } from './hooks/useConnectToken';
import { AuthSelector, ConfigSelector } from './selectors';
import { setInitialState, setIsAuthorized } from './slices/authSlice';
import { setOauthInitialState, setServiceData } from './slices/oauthSlice';
import { IAccessToken, ITokenInfo } from './types';

const AuthContext = createContext<{
  logout: () => void;
  checkAccessToken: (
    token: IAccessToken,
  ) => Promise<ITokenInfo | Record<string, unknown>>;
}>({
  logout: () => {},
  checkAccessToken: () => Promise.resolve({}),
});

export const AuthProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const configState = useAppSelector(ConfigSelector);
  const authState = useAppSelector(AuthSelector);
  const { pathname, search } = useLocation();
  const { registerSession } = useNodeBackService();
  const token = getAccessToken();
  const [isRefreshing, setIsRefreshing] = useState(false);

  const checkpointUrl =
    window.CHECKPOINT.api_urls.CHECKPOINT || configState.apis?.CHECKPOINT;
  const accessToken = token?.access_token;
  const initialRender = useRef(false);
  const { setItem } = useLocalStorage();
  const { id: userId } = useAppSelector(UserInfoSelector);
  const { bindAccount } = useConnectToken();

  const authDBService = useAuthDBService();

  const logout = useCallback(() => {
    clearAccessToken();
    clearUsername();
    dispatch(setInitialState());
    dispatch(setMenuInitialState());
    dispatch(setUserInitialState());
    dispatch(setOauthInitialState());
    dispatch(setIsAuthorized(false));

    history.push(Routes.AUTH);
  }, [history]);

  const checkAccessToken = async (verifiable: IAccessToken = token) => {
    if (verifiable) {
      try {
        return await authDBService.checkAccessToken(
          `${verifiable.access_token}`,
        );
      } catch (e) {
        logout();
      }
    }
    return Promise.reject();
  };

  const updateToken = async () => {
    if (token) {
      const { exp } = token;
      const diff = differenceInMinutes(new Date(exp * 1000), new Date());

      if (diff < MINIMAL_TOKEN_DIFF) {
        try {
          setIsRefreshing(true);
          // TODO: replace fetch with axios in run-ui
          const response = await fetch(
            `https://${checkpointUrl}/api/1.0/oauth/refresh?scope=backoffice`,
            {
              credentials: 'include',
              redirect: 'error',
            },
          );
          const newToken = await response.json();
          const tokenInfo = await checkAccessToken(newToken);

          saveAccessToken({ ...newToken, ...tokenInfo });
          await registerSession(newToken?.access_token);

          const { services } = tokenInfo;
          dispatch(setServiceData(services));

          window.location.reload();
        } catch (e) {
          console.error(e);
          logout();
        } finally {
          const event = new CustomEvent('onUpdateAccessToken');
          window.dispatchEvent(event);
          setIsRefreshing(false);
        }
      }
    }
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (checkpointUrl) {
      if (!initialRender.current) {
        initialRender.current = true;
        updateToken();
      }

      if (!isRefreshing) {
        intervalId = setInterval(updateToken, CHECK_TOKEN_INTERVAL);
      }
    }

    return () => clearInterval(intervalId);
  }, [isRefreshing, accessToken, checkpointUrl]);

  const setLastPage = (path: string, searchParams: string) => {
    const isMatch =
      matchPath(path, {
        path: [
          Routes.MODULE_AUTH,
          Routes.AUTH,
          '/crm/document-:id',
          '/cprm/document-:id',
          '/storybook',
        ],
        exact: true,
      }) !== null;

    if (!isMatch) {
      setItem(LOCAL_STORAGE_KEY_LAST_PAGE, `${path}${searchParams}`);
    }
  };

  useEffect(() => {
    setLastPage(pathname, search);
  }, [pathname, search]);

  useEffect(() => {
    const hasAuthKey = Boolean(token?.access_token);
    dispatch(setIsAuthorized(hasAuthKey));
  }, [accessToken]);

  useEffect(() => {
    bindAccount(userId);
  }, [userId]);

  const checkSession = async () => {
    try {
      const response = await authDBService.getSessionByUserId(userId);

      if (
        response?.status === StatusCodes.UNAUTHORIZED ||
        response?.status === StatusCodes.FORBIDDEN
      ) {
        logout();
      }
    } catch (err: any) {
      console.error(err);
    }
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (!authState.currentSessionId && authState.isAuthorized) {
      /**
       * Case when user clears local storage and then refresh page
       * Redux store resets after page refresh
       * In this case need to logout user
       * Because auth data, user data are empty
       */
      logout();
    } else if (authState.currentSessionId && authState.isAuthorized && userId) {
      intervalId = setInterval(checkSession, CHECK_SESSION_INTERVAL);
    }

    return () => clearInterval(intervalId);
  }, [authState.currentSessionId, authState.isAuthorized, userId]);

  const context = useMemo(
    () => ({
      checkAccessToken,
      logout,
    }),
    [checkAccessToken, logout],
  );

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
