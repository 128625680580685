import { useCallback, useEffect, useMemo } from 'react';

import { CPRM_SERVICE, CRM_SERVICE } from '../components/auth/const';
import { useServices } from '../components/auth/hooks/useServices';
import {
  setCprmBookmarksState,
  setCrmBookmarksState,
  setCrmMenuState,
  setCprmMenuState,
} from '../components/menu/reducer';
import {
  apiService,
  createInstance,
  updateInstanceToken,
} from '../components/services/Api/Api.service';
import { getAccessToken } from '../components/services/Cookies.service';
import { useAppDispatch, useAppSelector } from '../store/hooks';

import { mapMenu } from './helpers';
import { CrmMenuStateSelector, CprmMenuStateSelector } from './selectors';
import {
  Service,
  TCrmBookmarksData,
  TCrmMenu,
  TInitializeCrmCprmMenu,
} from './types';
import { useApi } from './useApi';

let cachedCrmBookmarksData: TCrmBookmarksData = null;
let cachedCprmBookmarksData: TCrmBookmarksData = null;
const crmInstance = createInstance(CRM_SERVICE);
const cprmInstance = createInstance(CPRM_SERVICE);

export const useCrmsConfig = () => {
  const dispatch = useAppDispatch();
  const { allowedServiceNames } = useServices();
  const crmMenuState = useAppSelector(CrmMenuStateSelector);
  const cprmMenuState = useAppSelector(CprmMenuStateSelector);

  const crmMenuMap = useMemo(() => {
    return mapMenu('crm', { data: crmMenuState }) || [];
  }, [crmMenuState]);

  const cprmMenuMap = useMemo(() => {
    return mapMenu('cprm', { data: cprmMenuState }) || [];
  }, [cprmMenuState]);

  const { getApiUrl } = useApi();
  const baseCrmAPIUrl = getApiUrl(Service.CrmApi);
  const baseCprmAPIUrl = getApiUrl(Service.CprmApi);

  const accessToken = getAccessToken();

  updateInstanceToken(crmInstance, accessToken);
  updateInstanceToken(cprmInstance, accessToken);

  const crmApi = apiService(
    `${baseCrmAPIUrl}/clientsarea/crm/api`,
    crmInstance,
  );
  const cprmApi = apiService(
    `${baseCprmAPIUrl}/clientsarea/crm/api`,
    cprmInstance,
  );

  const checkAccessToCrm = (token: string, services: string[]) => {
    return Boolean(token) && services.includes('crm');
  };

  const checkAccessToCprm = (token: string, services: string[]) => {
    return Boolean(token) && services.includes('CPRM');
  };

  const getCrmBookmarkData = async (forceUpdate = false) => {
    if (!cachedCrmBookmarksData || forceUpdate) {
      cachedCrmBookmarksData = await crmApi.get({
        url: `${baseCrmAPIUrl}/rest/`,
        endPoint: 'bookmarks/',
      });
    }
    return cachedCrmBookmarksData;
  };

  const getCprmBookmarkData = async (forceUpdate = false) => {
    if (!cachedCprmBookmarksData || forceUpdate) {
      cachedCprmBookmarksData = await cprmApi.get({
        url: `${baseCprmAPIUrl}/rest/`,
        endPoint: 'bookmarks/',
      });
    }
    return cachedCprmBookmarksData;
  };

  const getCrmMenuData = async (forceUpdate = false) => {
    if (crmMenuState.length === 0 || forceUpdate) {
      return crmApi.get({
        url: `${baseCrmAPIUrl}/rest/menu/`,
        endPoint: 'run-ui/menu/',
      });
    }
    return { data: crmMenuState };
  };

  const getCprmMenuData = async (forceUpdate = false) => {
    if (cprmMenuState.length === 0 || forceUpdate) {
      return cprmApi.get({
        url: `${baseCprmAPIUrl}/rest/menu/`,
        endPoint: 'run-ui/menu/',
      });
    }
    return { data: cprmMenuState };
  };

  const getBookmarks = async (
    forceUpdate = false,
    accessToCrm = false,
    accessToCprm = false,
  ) => {
    if (accessToCrm) {
      try {
        const crmBookmarks = await getCrmBookmarkData(forceUpdate);
        dispatch(setCrmBookmarksState(crmBookmarks));
      } catch (e) {
        console.error('CRM bookmarks error', e);
      }
    }

    if (accessToCprm) {
      try {
        const cprmBookmarks = await getCprmBookmarkData(forceUpdate);
        dispatch(setCprmBookmarksState(cprmBookmarks));
      } catch (e) {
        console.error('CPRM bookmarks error', e);
      }
    }
  };

  const getCrmMenu = async (forceUpdate = false) => {
    try {
      const crmMenuRes: TCrmMenu = await getCrmMenuData(forceUpdate);
      dispatch(setCrmMenuState(crmMenuRes?.data));
    } catch (e) {
      console.error('CRM menu error', e);
    }
  };

  const getCprmMenu = async (forceUpdate = false) => {
    try {
      const cprmMenuRes: TCrmMenu = await getCprmMenuData(forceUpdate);
      dispatch(setCprmMenuState(cprmMenuRes?.data));
    } catch (e) {
      console.error('CPRM menu error', e);
    }
  };

  const initializeCrmCprmMenu = ({
    crmMenuForceUpdate = false,
    cprmMenuForceUpdate = false,
    services,
    token,
  }: TInitializeCrmCprmMenu) => {
    const hasAccessToCrm = checkAccessToCrm(token, services);
    const hasAccessToCprm = checkAccessToCprm(token, services);

    if (hasAccessToCrm) {
      getCrmMenu(crmMenuForceUpdate);
    }
    if (hasAccessToCprm) {
      getCprmMenu(cprmMenuForceUpdate);
    }
    getBookmarks(false, hasAccessToCrm, hasAccessToCprm);
  };

  const onUpdateMenu = useCallback(() => {
    const hasAccessToCrm = checkAccessToCrm(
      accessToken?.access_token,
      allowedServiceNames,
    );
    if (hasAccessToCrm) {
      getCrmMenu(true);
    }

    const hasAccessToCprm = checkAccessToCrm(
      accessToken?.access_token,
      allowedServiceNames,
    );
    if (hasAccessToCprm) {
      getCprmMenu(true);
    }
  }, [accessToken, allowedServiceNames]);

  useEffect(() => {
    /**
     onUpdateMenu - event for external apps for force updating menu
     code example:
     const event = new CustomEvent('onUpdateMenu');
     window.dispatchEvent(event);
     */
    window.addEventListener('onUpdateMenu', onUpdateMenu);
    return () => {
      window.removeEventListener('onUpdateMenu', onUpdateMenu);
    };
  }, []);

  return useMemo(
    () => ({
      initializeCrmCprmMenu,
      crmsMenu: [...crmMenuMap, ...cprmMenuMap],
    }),
    [crmMenuMap, cprmMenuMap],
  );
};
