import {
  Chip as ChipMUI,
  List as ListMUI,
  ListItem as ListItemMUI,
  ListItemText as ListItemTextMUI,
  ListItemIcon as ListItemIconMUI,
} from '@mui/material';
import { blockNonNativeProps, styled } from 'react-ui-kit-exante';

import { StyledProps } from '../../shared/helpers/types';

import { COLLAPSED_MENU_WIDTH, MENU_WIDTH } from './const';
import {
  ChipProps,
  CollapseMenuButtonProps,
  MenuProps,
  StyledListItemProps,
  StyledListItemTextProps,
  StyledMenuItemProps,
} from './types';

export const StyledMenuWrapper = styled('aside', {
  shouldForwardProp: blockNonNativeProps(['isCollapsed']),
})<MenuProps>(({ isCollapsed }) => ({
  flex: `0 0 ${isCollapsed ? COLLAPSED_MENU_WIDTH : MENU_WIDTH}px`,
  width: `${isCollapsed ? COLLAPSED_MENU_WIDTH : MENU_WIDTH}px`,
  transition: 'flex-basis .4s ease-out, width .4s ease-out',
  height: '100vh',
  userSelect: 'none',
}));

export const StyledMenu = styled('div', {
  shouldForwardProp: blockNonNativeProps(['isCollapsed']),
})<MenuProps>(({ isCollapsed, theme }) => ({
  position: 'fixed',
  userSelect: 'none',
  width: `${isCollapsed ? COLLAPSED_MENU_WIDTH : MENU_WIDTH}px`,
  height: '100vh',
  paddingBottom: 56,

  backgroundColor: theme.color.bg.primary,
  zIndex: 2,
  border: 'none',
  overflowY: 'auto',
  overflowX: isCollapsed ? 'hidden' : 'visible',
  transition: 'width .4s ease-out',

  scrollbarWidth: 'thin',
  scrollbarColor: `${theme.color.bg.basic} ${theme.color.bg.primary}`,
}));

export const StyledMenuList = styled(ListMUI)(() => ({
  '&.MuiList-root': {
    padding: 0,
    pointerEvents: 'all',
    width: `calc(${MENU_WIDTH}px - 0.5em)`,
  },

  '& .MenuItemChip': {
    position: 'relative',
    display: 'block',
  },
}));

export const Chip = styled(ChipMUI)<ChipProps>(({ level, theme }) => ({
  '&.MuiChip-root': {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.color.typo.action}`,
    borderRadius: '34px',
    height: '20px',
    position: 'absolute',
    right: 8,
    top: level === 1 ? 16 : 5,
    width: 'auto',
  },

  '& .MuiChip-label': {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    color: theme.color.typo.action,
    lineHeight: '16px',
    padding: 4,
  },
}));

export const SmallChip = styled(ChipMUI)<StyledProps>(({ theme }) => ({
  '&.MuiChip-root': {
    borderRadius: '24px',
    border: `1px solid ${theme.color.typo.action}`,
    margin: '0 2px',
    color: theme.color.typo.action,
    backgroundColor: 'transparent',
    height: '17px',
  },

  '.MuiChip-label': {
    padding: '0 5px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    color: theme.color.typo.action,
  },
}));

export const CollapseMenuButton = styled('div', {
  shouldForwardProp: blockNonNativeProps(['isCollapsed']),
})<CollapseMenuButtonProps>(({ theme, isCollapsed }) => ({
  cursor: 'pointer',
  position: 'fixed',
  height: 56,
  width: `${isCollapsed ? COLLAPSED_MENU_WIDTH : MENU_WIDTH}px`,
  bottom: 0,
  color: theme.color.typo.secondary,
  backgroundColor: theme.color.bg.primary,
  backgroundRepeat: 'no-repeat',
  boxShadow: `0 -1px 3px ${theme.color.bg.basic}`,
  border: 0,

  '&:hover': {
    color: theme.color.typo.primary,
  },

  '.CollapseIcon': {
    transform: isCollapsed ? 'rotate(90deg)' : 'rotate(-90deg)',
    transition: 'transform .4s ease-out',
    position: 'absolute',
    top: 16,
    left: 16,
  },
}));

export const StyledMenuItem = styled('div', {
  shouldForwardProp: blockNonNativeProps(['hasChip', 'linkTo']),
})<StyledMenuItemProps>(({ hasChip, linkTo }) => ({
  alignItems: 'center',
  cursor: linkTo ? 'cursor' : 'default',
  display: 'block',
  opacity: linkTo ? 1 : 0.4,
  position: hasChip ? 'relative' : 'inherit',
}));

export const StyledListItem = styled(ListItemMUI, {
  shouldForwardProp: blockNonNativeProps([
    'open',
    'isActive',
    'isMainMenuItem',
  ]),
})<StyledListItemProps>(({ isMainMenuItem, open, isActive, theme }) => ({
  '&.MuiListItem-root': {
    color:
      open || isActive ? theme.color.typo.primary : theme.color.typo.secondary,
    cursor: 'pointer',
    boxShadow:
      open || isActive
        ? `inset 2px 0px 0px ${theme.color.typo.action}`
        : 'none',
    padding: '8px 8px 8px 16px',
    backgroundColor:
      open || isActive
        ? theme.color.input.bg.focus
        : theme.color.input.bg.default,

    ...(!isMainMenuItem
      ? {
          padding: '0 16px 0',
          boxShadow: 'none !important',
          background: 'none !important',
        }
      : null),
  },

  '.ListItemIcon': {
    img: {
      backgroundColor:
        open || isActive
          ? `${theme.color.input.bg.focus} !important`
          : `${theme.color.input.bg.default} !important`,
    },
  },

  '&:hover': {
    backgroundColor: theme.color.input.bg.hover,
    boxShadow: `inset 2px 0px 0px ${theme.color.typo.action}`,

    '.ListItemIcon': {
      img: {
        backgroundColor: `${theme.color.input.bg.hover} !important`,
      },
    },
  },
}));

export const StyledListItemText = styled(
  ListItemTextMUI,
)<StyledListItemTextProps>(
  ({ isMainMenuItem, open, isActive, isSubmenuItem, theme }) => ({
    '&.MuiListItemText-root': {
      color:
        open || isActive
          ? theme.color.typo.primary
          : theme.color.typo.secondary,
      cursor: 'pointer',
      paddingLeft: isMainMenuItem || isSubmenuItem ? 0 : '32px',
      margin: !isMainMenuItem ? 0 : '',

      '&:hover': {
        color: theme.color?.typo?.promo,
      },
    },

    '& .MuiTypography-root': {
      fontFamily: theme.font.main,
      fontSize: '14px',
      fontWeight: open || isActive ? 600 : 500,
      ...(!isMainMenuItem
        ? {
            fontSize: '13px',
            padding: '8px 0',
            lineHeight: '16px',
          }
        : null),
    },
  }),
);

export const StyledListItemIcon = styled(ListItemIconMUI)({
  '&.MuiListItemIcon-root': {
    minWidth: 'auto',
    marginRight: '8px',
  },
});
